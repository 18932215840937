import { ChangeEvent, ReactElement, useState } from "react";
import { Player } from "../../../../persistence/model/Player";
import { PlayerHelper } from "../../../../persistence/helpers/PlayerHelper";




interface Props {
  className: any;
  index: number;
  onPlayerAdd: (player: Player) => void;
}

export const PlayersInput = (props: Props): ReactElement => {

  const [newPlayerNameState, setNewPlayerNameState] = useState<string>("");

  const handleNewPlayerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPlayerNameState(e.target.value);
  };

  return (
    <div className={`entry ${props.className}`}>
      <div className="entry-number next">{props.index + 1}</div>
      <div className="entry-player"><input type="text" placeholder="Add Player" value={newPlayerNameState} onChange={handleNewPlayerNameChange} />
      <span className="entry-add icon link next" onClick={handlePlayerAdd}>Add</span>
      </div>
    </div>
  );

  function handlePlayerAdd() {
    if (!newPlayerNameState) return;

    let newPlayer = PlayerHelper.create(newPlayerNameState);
    props.onPlayerAdd(newPlayer);

    setNewPlayerNameState("");
  }
};
