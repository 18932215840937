import { Match } from "../model/Match";
import { Player } from "../model/Player";
import { Round } from "../model/Round";
import { uuid } from "../../utils/UuidUtils";

export class MatchHelper {
  public static create(playerOneId: string, playerTwoId: string): Match {
    return {
      id: uuid(),
      playerOneId: playerOneId,
      playerTwoId: playerTwoId,
      finished: false,
    };
  }

  public static createFinishedMatch(match: Match, winnerId?: string, loserId?: string): Match {
    return {
      ...match,
      ...{
        winnerId: winnerId,
        loserId: loserId,
        finished: true,
      },
    };
  }

  public static resetFinishedMatch(match: Match): Match {
    return {
      ...match,
      ...{
        winnerId: undefined,
        loserId: undefined,
        finished: false,
      },
    };
  }

  public static updateMatch(round: Round, match: Match): Round {
    let matchIndex = round.matches.findIndex((m) => m.id === match.id);
    return {
      id: round.id,
      matches: [...round.matches.slice(0, matchIndex), match, ...round.matches.slice(matchIndex + 1)],
      bye: round.bye,
    };
  }

  public static getOpponent(match: Match, player: Player): string {
    if (match.playerOneId === player.id) {
      return match.playerTwoId;
    } else if (match.playerTwoId === player.id) {
      return match.playerOneId;
    } else {
      console.error("found a match where the player was not found");
      return "";
    }
  }

  public static findMatchById(round: Round, matchId: string): Match {
    return round.matches.find((m) => m.id === matchId)!!;
  }

  public static findMatchByPlayerId(round: Round, playerId: string): Match {
    return round.matches.find((m) => m.playerOneId === playerId || m.playerTwoId === playerId)!!;
  }
}
