import { Tournament } from "../model/Tournament";

export class TournamentHelper {
  public static createNewTournament(): Tournament {
    return {
      players: [],
      rounds: [],
    };
  }
}
