import { HistoryEntry } from "../model/HistoryEntry";

export class HistoryHelper {
  public static createEntry(matchId: string, opponentId: string): HistoryEntry {
    return {
      matchId: matchId,
      opponentId: opponentId,
    };
  }
}
