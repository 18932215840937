import { ReactElement } from "react";
import { useRecoilValue } from "recoil";
import { AppState, recoilState } from "../../../../persistence/Persistance";
import { PlayerHelper } from "../../../../persistence/helpers/PlayerHelper";

interface Props {
  className: any;
  table: number;
  bye: string;
}

export const ByeEntry = (props: Props): ReactElement => {
  const appState = useRecoilValue<AppState>(recoilState);

  const player = PlayerHelper.getPlayerById(appState.tournament.players, props.bye);

  return (
    <div className={`entry ${props.className}`}>
      <div className="entry-number">{props.table}</div>
      <div className="entry-player next">
        <span className="entry-player-name">{player.name}</span>
      </div>
      <div className="entry-tie greyed-out">-</div>
      <div className="entry-player greyed-out">Bye</div>
    </div>
  );
};
