import * as React from "react";

export interface Props {
  open: boolean;
  onClose: (confirm: boolean) => void;
}

export const EndTournamentDialog = (props: Props) => {
  const { open, onClose } = props;

  function render() {
    return (
      <div className="dialog-container" onClick={() => onClose(false)}>
        {open && (
          <div className="dialog-background">
            <div className="dialog" onClick={onClickDialog}>
              <div className="dialog-top">
                <div className="dialog-h1 edit">Caution</div>
                <div className="dialog-body dark">Ending the tournament will erase all results.</div>
                <div className="dialog-body dark">Are you sure?</div>
              </div>
              <div className="dialog-button-pair">
                <div className="dialog-button dialog-button-left link edit" onClick={() => onClose(false)}>
                  Cancel
                </div>
                <div className=" dialog-button dialog-button-right link next" onClick={() => onClose(true)}>
                  Yes
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function onClickDialog(e: React.MouseEvent<HTMLElement>) {
    // this is needed to prevent the dialog from closing when clicking it
    // we only want to close the dialog when we click outside of it.
    e.stopPropagation();
  }

  return render();
};
