import { Tournament } from "../model/Tournament";
import { Round } from "../model/Round";
import { MatchMakingHelper } from "./MatchmakingHelper";
import { Player } from "../model/Player";
import { HistoryHelper } from "./HistoryHelper";
import { PlayerHelper } from "./PlayerHelper";
import { Match } from "../model/Match";
import { uuid } from "../../utils/UuidUtils";

export class RoundHelper {
  public static create(matches: Match[], bye: string | undefined): Round {
    return {
      id: uuid(),
      matches: matches,
      bye: bye,
    };
  }

  public static next(tournament: Tournament): Round {
    return MatchMakingHelper.createRound(tournament);
  }

  public static recordOpponents(round: Round, players: Player[]): Player[] {
    let updatedPlayers: Player[] = [];

    round.matches.forEach((match) => {
      let p1 = PlayerHelper.getPlayerById(players, match.playerOneId);
      let p2 = PlayerHelper.getPlayerById(players, match.playerTwoId);

      updatedPlayers.push({ ...p1, history: [...p1.history, ...[HistoryHelper.createEntry(match.id, p2.id)]] });
      updatedPlayers.push({ ...p2, history: [...p2.history, ...[HistoryHelper.createEntry(match.id, p1.id)]] });
    });

    if (round.bye) {
      let byedPlayer = PlayerHelper.getPlayerById(players, round.bye);
      updatedPlayers.push(byedPlayer);
    }

    players.filter((p) => p.dropped).forEach((p) => updatedPlayers.push(p));

    return updatedPlayers;
  }

  public static getCurrentRound(tournament: Tournament): Round {
    let length = tournament.rounds.length;
    return tournament.rounds[length - 1];
  }

  public static getIndexById(rounds: Round[], id: string): number {
    return rounds.findIndex((r) => r.id === id)!!;
  }

  public static updateRound(rounds: Round[], round: Round): Round[] {
    let roundIndex = rounds.findIndex((r) => r.id === round.id);
    return [...rounds.slice(0, roundIndex), round, ...rounds.slice(roundIndex + 1)];
  }

  public static deleteCurrentRound(tournament: Tournament): Tournament {
    return {
      ...tournament,
      rounds: tournament.rounds.splice(0, -1),
    };
  }

  public static getCurrentRoundNumber(tournament: Tournament): number {
    return tournament.rounds.length;
  }

  public static getExpectedRounds(playerAmount: number): number {
    return Math.ceil(Math.log2(playerAmount));
  }
}
