import { ReactElement } from "react";
import { toDecimal } from "../../../../utils/NumberUtil";
import { Resistance } from "../../../../persistence/helpers/ResistanceHelper";
import { Score } from "../../../../persistence/model/Score";

interface Props {
  className: any;
  index: number;
  resistance: Resistance;
}

export const StandingsEntry = (props: Props): ReactElement => {
  const resistance = props.resistance;
  const player = props.resistance.player;

  return (
    <div className={`entry ${props.className}`}>
      <div className="entry-number">{props.index + 1}</div>
      <div className="entry-player">
        <span className="entry-player-name">{player.name}</span>
        {renderInformation()}
      </div>
    </div>
  );

  function renderInformation() {
    return (
      <div className="entry-standing">
        {renderScore(player.score)}
        {renderPoints(resistance.points)}
        {renderResistance(resistance.opponentsResistance)}
        {renderResistance(resistance.opponentsOpponentsResistance)}
      </div>
    );
  }

  function renderScore(score: Score) {
    return (
      <span>
        {score.win}/{score.lose}/{score.draw}
      </span>
    );
  }

  function renderPoints(points: number) {
    return <span>{points}</span>;
  }

  function renderResistance(resistance: number) {
    return <span>{toDecimal(resistance)}</span>;
  }
};
