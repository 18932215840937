import { groupBy } from "../../utils/ArrayUtils";
import { Player } from "../model/Player";
import { Score } from "../model/Score";
import { Tournament } from "../model/Tournament";
import { MatchHelper } from "./MatchHelper";
import { PlayerHelper } from "./PlayerHelper";
import { ScoreHelper, ScoreProperties } from "./ScoreHelper";

export type Resistance = {
  player: Player;
  points: number;
  resistance: number;
  opponentsResistance: number;
  opponentsOpponentsResistance: number;
};

export class ResistanceHelper {
  public static getSortedStandings(tournament: Tournament): Resistance[] {
    return this.orderByTardiness(tournament);
  }

  //1. Order by tardiness
  private static orderByTardiness(tournament: Tournament): Resistance[] {
    // TODO: Implement order by tardiness
    return this.orderByPoints(tournament);
  }

  //2. Order by points
  private static orderByPoints(tournament: Tournament): Resistance[] {
    // First order by points

    let playerScores = tournament.players.map((p) => {
      return {
        player: p,
        points: ScoreHelper.calculatePoints(p.score),
      };
    });
    let order = playerScores.sort((p1, p2) => p1.points - p2.points).map((p) => p.player);

    // Then calculate the resistances and group from there
    let resistances = ResistanceHelper.getResistances(order);
    let grouped = groupBy(resistances, (r) => r.points);

    //3. Opponent's Resistance
    let finalOrder: Resistance[] = [];
    grouped.forEach((values) => {
      let subOrder = this.orderByOpponentsResistance(tournament, values);
      subOrder.forEach((r) => finalOrder.push(r));
    });

    return finalOrder;
  }

  //3. Opponent's Resistance
  private static orderByOpponentsResistance(tournament: Tournament, order: Resistance[]): Resistance[] {
    let newOrder = order.sort((p1, p2) => p1.opponentsResistance - p2.opponentsResistance);
    let grouped = groupBy(newOrder, (r) => r.opponentsResistance);

    //4. Opponent's Opponent's Resistance
    let finalOrder: Resistance[] = [];
    grouped.forEach((values) => {
      let subOrder = this.orderByOpponentsOpponentsResistance(tournament, values);
      subOrder.forEach((r) => finalOrder.push(r));
    });

    return finalOrder;
  }

  //4. Opponent's Opponent's Resistance
  private static orderByOpponentsOpponentsResistance(tournament: Tournament, order: Resistance[]): Resistance[] {
    let newOrder = order.sort((p1, p2) => p1.opponentsOpponentsResistance - p2.opponentsOpponentsResistance);
    let grouped = groupBy(newOrder, (r) => r.opponentsOpponentsResistance);

    //5. Head-to-Head
    let finalOrder: Resistance[] = [];
    grouped.forEach((values) => {
      let subOrder = this.orderByHeadToHead(tournament, values);
      subOrder.forEach((r) => finalOrder.push(r));
    });

    return finalOrder;
  }

  //5. Head-to-Head
  private static orderByHeadToHead(tournament: Tournament, order: Resistance[]): Resistance[] {
    let newOrder = order.sort((r1, r2) => {
      let opponents = PlayerHelper.getOpponentIds(r1.player);
      let played = opponents.includes(r2.player.id);

      if (played) {
        let matchId = r1.player.history.find((m) => m.opponentId === r2.player.id)?.matchId!!;
        let round = tournament.rounds.find((r) => r.matches.find((m) => m.id === matchId))!!;
        let winnerId = MatchHelper.findMatchById(round, matchId).winnerId;
        if (winnerId) {
          if (winnerId === r1.player.id) {
            return 1;
          } else {
            return -1;
          }
        }
      }

      //6. Random (in case of draw)
      return this.coinFlip();
    });

    return newOrder;
  }

  //6. Random
  private static coinFlip(): number {
    switch (Math.random() >= 0.5) {
      default:
      case true:
        return 1;

      case false:
        return -1;
    }
  }

  private static getResistances(players: Player[]): Resistance[] {
    let resistances = new Map();
    players.forEach((p) => {
      resistances.set(p.id, this.calculateResistance(p.score));
    });

    let oppResistances = new Map();
    resistances.forEach((r, p) => {
      let player = PlayerHelper.getPlayerById(players, p);
      let opponentIds = PlayerHelper.getOpponentIds(player);
      oppResistances.set(p, this.calculateOpponentsResistance(resistances, opponentIds));
    });

    let oppOppResistances = new Map();
    oppResistances.forEach((r, p) => {
      let player = PlayerHelper.getPlayerById(players, p);
      let opponentIds = PlayerHelper.getOpponentIds(player);
      oppOppResistances.set(p, this.calculateOpponentsResistance(oppResistances, opponentIds));
    });

    return players.map((p) => {
      return {
        player: p,
        points: ScoreHelper.calculatePoints(p.score),
        resistance: resistances.get(p.id)!!,
        opponentsResistance: oppResistances.get(p.id)!!,
        opponentsOpponentsResistance: oppOppResistances.get(p.id)!!,
      };
    });
  }

  public static calculateResistance(score: Score): number {
    // calculate initial score
    let points = ScoreHelper.calculatePoints(score);
    let rounds = ScoreHelper.calculateRounds(score);

    // calculate resistance, map back to a value of 0~1 by
    let resistance = points / rounds / ScoreProperties.RESISTANCE_VALUE_WIN;

    // clamp at 25%
    let clampedResistance = Math.max(resistance, 0.25);
    return clampedResistance;
  }

  private static calculateOpponentsResistance(resistanceMap: Map<string, number>, opponentIds: string[]): number {
    let total = 0;
    opponentIds.forEach((o) => {
      let res = resistanceMap.get(o);
      if (res) total += res;
      else console.warn(`error, opponent with id ${o} didnt have resistance`);
    });

    return total / opponentIds.length;
  }
}

/*

These rules dictate the calculation of resistance:

A win is counted as 1, a tie is counted as 0.5, and a loss 0.

The resistance for a single opponent is determined as the sum of these values
divided by the number of rounds. For example, if an opponent finished 3–1–1,
their contribution is (1 + 1 + 1 + 0.5 + 0) / 5 = 70%.

An opponent cannot contribute less than 25%. If the calculated score is less
than 25%, it defaults to 25%.

*/
