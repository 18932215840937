import type { RecoilState } from "recoil";
import { atom, useRecoilValue } from "recoil";
import { Tournament } from "./model/Tournament";
import { TournamentHelper } from "./helpers/TournamenHelper";
import { useEffect } from "react";
import { States } from "./States";

const APPSTATE_VERSION = "20240307";

export interface StateData {
  roundId?: string;
  fromState?: string;
}

export interface AppState {
  state: string;
  stateData?: StateData;
  tournament: Tournament;
}

export enum LocalStorageKey {
  APP_STATE = "APP_STATE",
  VERSION = "VERSION",
}

function loadAppStateFromLocalStorage(): AppState {
  let stringifiedJSON: string | null = window.localStorage.getItem(LocalStorageKey.APP_STATE);

  if (typeof stringifiedJSON === "string") {
    let loaded: AppState = JSON.parse(stringifiedJSON);

    let appStateVersion = window.localStorage.getItem(LocalStorageKey.VERSION);
    if (appStateVersion === APPSTATE_VERSION) {
      return loaded;
    }
  }

  let BlankAppState: AppState = {
    state: States.TOURNAMENT_CREATE,
    tournament: TournamentHelper.createNewTournament(),
  };

  return BlankAppState;
}

function saveAppStateToLocalStorage(appState: AppState) {
  window.localStorage.setItem(LocalStorageKey.APP_STATE, JSON.stringify(appState));
  window.localStorage.setItem(LocalStorageKey.VERSION, APPSTATE_VERSION);
}

export const recoilState: RecoilState<AppState> = atom({
  default: loadAppStateFromLocalStorage(),
  key: "initialAppState",
});

export const Persistance = () => {
  const appState = useRecoilValue<AppState>(recoilState);

  useEffect((): void => {
    saveAppStateToLocalStorage(appState);
  }, [appState]);

  return <div className="persistance" />;
};
