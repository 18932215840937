export const States = {
  TOURNAMENT_CREATE: "tournament-create",
  PLAYERS_ADD: "players-add",
  PLAYERS_EDIT: "players-edit",
  ROUND_PROPOSE: "round-propose",
  ROUND_PLAYING: "round-playing",
  ROUND_EDIT: "round-edit",
  STANDINGS_FINAL: "standings-final",
  STANDINGS_INTERIM: "standings-interim",
};
