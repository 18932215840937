import React from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { PlayerEditDialog } from "./PlayerEditDialog";

export const PlayerEditButton = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const [open, setOpen] = React.useState(false);

  function render() {
    return (
      <div>
        <div className="subnav-button navigation link" onClick={handleClickOpen}>
          Add/Drop Players
        </div>
        {/* Dialog cannot be inside of the button div, otherwise the dialog won't close. */}
        <PlayerEditDialog open={open} onClose={handleCloseDialog} />
      </div>
    );
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleCloseDialog(confirm: boolean) {
    setOpen(false);

    if (confirm) {
      goToPlayerEditView();
    }
  }

  function goToPlayerEditView() {
    setAppState({
      state: States.PLAYERS_EDIT,
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  return render();
};
