import React from "react";
import { useSetRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { TournamentHelper } from "../../../persistence/helpers/TournamenHelper";
import { EndTournamentDialog } from "./EndTournamentDialog";

export interface Props {
  className: string;
  showDialog: boolean;
}

export const EndTournamentButton = (props: Props) => {
  const setAppState = useSetRecoilState<AppState>(recoilState);

  const [open, setOpen] = React.useState(false);

  function render() {
    return (
      <div>
        <h1 className={props.className} onClick={handleClickEnd}>
          End
        </h1>
        {/* Dialog cannot be inside of the button div, otherwise the dialog won't close. */}
        <EndTournamentDialog open={open} onClose={handleCloseDialog} />
      </div>
    );
  }

  function handleClickEnd() {
    if(props.showDialog){
      setOpen(true);
    } else {
      goToCreateTournamentView()
    }
  }

  function handleCloseDialog(confirm: boolean) {
    setOpen(false);

    if (confirm) {
      goToCreateTournamentView();
    }
  }

  function goToCreateTournamentView() {
    setAppState({
      state: States.TOURNAMENT_CREATE,
      tournament: TournamentHelper.createNewTournament(),
    });
  }

  return render();
};
