import { ReactElement } from "react";
import { Player } from "../../../../persistence/model/Player";

interface Props {
  className: any;
  index: number;
  player: Player;
  onPlayerRemove: (player: Player) => void;
}

export const PlayersEntry = (props: Props): ReactElement => {
  return (
    <div className={`entry ${props.className}`}>
      <div className="entry-number">{props.index + 1}</div>
      <div className="entry-player">
        <span className="entry-player-name">{props.player.name}</span>
        <span className="entry-remove link edit" onClick={() => props.onPlayerRemove(props.player)}>
          Drop
        </span>
      </div>
    </div>
  );
};
