import { Match } from "tournament-pairings/dist/Match";
import { PlayerHelper } from "./PlayerHelper";
import { Resistance } from "./ResistanceHelper";
import { Player } from "../model/Player";
import { ScoreHelper } from "./ScoreHelper";

export function convertPlayers(resistances: Resistance[]): SwissPlayer[] {
  let players = resistances.map((s) => {
    return {
      id: s.player.id,
      score: s.points,
      receivedBye: s.player.byed,
      avoid: PlayerHelper.getOpponentIds(s.player),
      rating: s.resistance,
    };
  });

  return players;
}

interface SwissPlayer {
  id: string | number;
  score: number;
  pairedUpDown?: boolean;
  receivedBye?: boolean;
  avoid?: Array<string | number>;
  colors?: Array<"w" | "b">;
  rating?: number | null;
}

export function sortSwissMatches(players: Player[], swiss: Match[]): Match[] {
  let tables = swiss.map((match) => {
    let points = 0;

    if (match.player1 !== null) {
      let p = PlayerHelper.getPlayerById(players, match.player1 as string);
      points += ScoreHelper.calculatePoints(p.score);
    }

    if (match.player2 !== null) {
      let p = PlayerHelper.getPlayerById(players, match.player2 as string);
      points += ScoreHelper.calculatePoints(p.score);
    }

    return { points, match };
  });

  let sorted = tables.sort((one, two) => two.points - one.points);

  return sorted.map((t) => t.match);
}
