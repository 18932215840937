import { ReactElement } from "react";
import { Match } from "../../../../persistence/model/Match";
import { useRecoilValue } from "recoil";
import { AppState, recoilState } from "../../../../persistence/Persistance";
import { PlayerHelper } from "../../../../persistence/helpers/PlayerHelper";
import { Player } from "../../../../persistence/model/Player";

interface Props {
  className: any;
  table: number;
  match: Match;
  onPlayerClicked: (match: Match, player: Player) => void;
  onDrawClicked: (match: Match) => void;
}

export const MatchEntry = (props: Props): ReactElement => {
  const appState = useRecoilValue<AppState>(recoilState);

  const match = props.match;
  const playerOne = PlayerHelper.getPlayerById(appState.tournament.players, match.playerOneId);
  const playerTwo = PlayerHelper.getPlayerById(appState.tournament.players, match.playerTwoId);

  const colors = getColors();

  return (
    <div className={`entry ${props.className}`}>
      <div className="entry-number">{props.table}</div>
      <div className={"entry-player link " + colors[0]} onClick={() => props.onPlayerClicked(match, playerOne)}>
        <span className="entry-player-name">{playerOne.name}</span>
      </div>
      <div className={"entry-tie link " + colors[1]} onClick={() => props.onDrawClicked(match)}>
        Tie
      </div>
      <div className={"entry-player link " + colors[2]} onClick={() => props.onPlayerClicked(match, playerTwo)}>
        <span className="entry-player-name">{playerTwo.name}</span>
      </div>
    </div>
  );

  function getColors() {
    if (!match.finished) return ["", "", ""];
    else if (match.playerOneId === match.winnerId) return ["next", "greyed-out", "alert"];
    else if (match.playerTwoId === match.winnerId) return ["alert", "greyed-out", "next"];
    else if (!match.winnerId && !match.loserId) return ["greyed-out", "standings", "greyed-out"];
    else return ["", "", ""];
  }
};
