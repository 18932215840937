import * as React from "react";
import { Round } from "../../../persistence/model/Round";
import { RoundHelper } from "../../../persistence/helpers/RoundHelper";

export interface Props {
  open: boolean;
  rounds: Round[];
  onClose: (value: string | null) => void;
}

export const RoundEditDialog = (props: Props) => {
  const { open, rounds, onClose } = props;

  function render() {
    return (
      <div className="dialog-container" onClick={() => onClose(null)}>
        {open && (
          <div className="dialog-background">
            <div className="dialog" onClick={onClickDialog}>
              <div className="dialog-top">
                <div className="dialog-h1 edit">Caution</div>
                <div className="dialog-body dark">Editing Round results will effect pairings in upcoming rounds.</div>
                <div className="dialog-body dark">Which round would you like to edit?</div>
              </div>
              <div className="dialog-button-column">
                {rounds.map((round) => (
                  <div className="dialog-button link next" key={round.id} onClick={() => onClose(round.id)}>
                    {roundIdToRoundText(round.id)}
                  </div>
                ))}
                <div className=" dialog-button dialog-button-last link edit" onClick={() => onClose(null)}>
                  Cancel
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function onClickDialog(e: React.MouseEvent<HTMLElement>) {
    // this is needed to prevent the dialog from closing when clicking it
    // we only want to close the dialog when we click outside of it.
    e.stopPropagation();
  }

  function roundIdToRoundText(id: string) {
    let index = RoundHelper.getIndexById(rounds, id);
    return "Round " + (index + 1);
  }

  return render();
};
