export function shuffle<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function groupBy<T>(array: T[], getKey: (item: T) => number) {
  let map: Map<number, T[]> = new Map();
  array.forEach((r) => {
    let key = getKey(r);
    let group = map.get(key) ?? [];
    map.set(key, [...group, ...[r]]);
  });

  return new Map([...map].sort((a, b) => b[0] - a[0]));
}

export function findDuplicates(array: any[]): any[] {
  const duplicates = Array<any>();
  
  for (let i = 0; i < array.length; i++) {
    for (let j = i + 1; j < array.length; j++) {
      if (array[i] === array[j] && !duplicates.includes(array[i])) {
        duplicates.push(array[i]);
      }
    }
  }
  
  return duplicates;
}
