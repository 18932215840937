import { ReactElement } from "react";
import { Match } from "../../../../persistence/model/Match";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../../persistence/Persistance";
import { PlayerHelper } from "../../../../persistence/helpers/PlayerHelper";
import { RoundHelper } from "../../../../persistence/helpers/RoundHelper";
import { MatchHelper } from "../../../../persistence/helpers/MatchHelper";
import { Player } from "../../../../persistence/model/Player";

interface Props {
  className: any;
  table: number;
  match: Match;
}

export const MatchEntry = (props: Props): ReactElement => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const match = props.match;
  const playerOne = PlayerHelper.getPlayerById(appState.tournament.players, match.playerOneId);
  const playerTwo = PlayerHelper.getPlayerById(appState.tournament.players, match.playerTwoId);

  const colors = getColors();

  return (
    <div className={`entry ${props.className}`}>
      <div className="entry-number">{props.table}</div>
      <div className={"entry-player link " + colors[0]} onClick={() => handlePlayerClicked(match, playerOne)}>
        <span className="entry-player-name">{playerOne.name}</span>
      </div>
      <div className={"entry-tie link " + colors[1]} onClick={() => handleDrawClicked(match)}>
        Tie
      </div>
      <div className={"entry-player link " + colors[2]} onClick={() => handlePlayerClicked(match, playerTwo)}>
        <span className="entry-player-name">{playerTwo.name}</span>
      </div>
    </div>
  );

  function getColors() {
    if (!match.finished) return ["", "", ""];
    else if (match.playerOneId === match.winnerId) return ["next", "greyed-out", "alert"];
    else if (match.playerTwoId === match.winnerId) return ["alert", "greyed-out", "next"];
    else if (!match.winnerId && !match.loserId) return ["greyed-out", "standings", "greyed-out"];
    else return ["", "", ""];
  }

  function handlePlayerClicked(match: Match, player: Player) {
    if (match.finished && match.winnerId === player.id) {
      resetMatch(match);
    } else {
      let round = RoundHelper.getCurrentRound(appState.tournament);
      let opponent = MatchHelper.getOpponent(match, player);
      let updatedMatch = MatchHelper.createFinishedMatch(match, player.id, opponent);
      let updatedRound = MatchHelper.updateMatch(round, updatedMatch);
      let updatedRounds = RoundHelper.updateRound(appState.tournament.rounds, updatedRound);

      setAppState({
        state: appState.state,
        tournament: {
          players: appState.tournament.players,
          rounds: updatedRounds,
        },
      });
    }
  }

  function handleDrawClicked(match: Match) {
    if (match.finished && match.winnerId === undefined) {
      resetMatch(match);
    } else {
      let round = RoundHelper.getCurrentRound(appState.tournament);
      let updatedMatch = MatchHelper.createFinishedMatch(match);
      let updatedRound = MatchHelper.updateMatch(round, updatedMatch);
      let updatedRounds = RoundHelper.updateRound(appState.tournament.rounds, updatedRound);

      setAppState({
        state: appState.state,
        tournament: {
          players: appState.tournament.players,
          rounds: updatedRounds,
        },
      });
    }
  }

  function resetMatch(match: Match) {
    let round = RoundHelper.getCurrentRound(appState.tournament);
    let updatedMatch = MatchHelper.resetFinishedMatch(match);
    let updatedRound = MatchHelper.updateMatch(round, updatedMatch);
    let updatedRounds = RoundHelper.updateRound(appState.tournament.rounds, updatedRound);

    setAppState({
      state: appState.state,
      tournament: {
        players: appState.tournament.players,
        rounds: updatedRounds,
      },
    });
  }
};
