import "./style.css";
import { ReactElement } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";

export const StandingsInterimView = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  function render(): ReactElement {
    return (
      <div className="pairings-round">
        <div className="container-wrapper">
          <div className="container">
            <p className="title">
              <span className="span">
                Round 1 <br />
              </span>
              <span className="text-wrapper-2">Pairings</span>
            </p>
            <div className="subnav">
              <div className="message">Add more Players?</div>
            </div>
            <div className="pairings">
              {/* <PairingsTable className="pairings-entry-instance" />
          <PairingsTable className="design-component-instance-node" text="2" text1="Yun" />
          <PairingsTable className="design-component-instance-node" text="3" text1="Hendrik-Jaap" />
          <PairingsTable className="design-component-instance-node" text="4" text1="Player Name" />
          <PairingsTable className="design-component-instance-node" text="5" text1="Player Name" />
          <PairingsTable className="pairings-entry-2" text="6" text1="Player Name" /> */}
            </div>
            <div className="title-2" onClick={handleReturn}>
              Back
            </div>
          </div>
        </div>
      </div>
    );
  }

  function handleReturn(): void {
    setAppState({
      state: States.ROUND_PROPOSE,
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  return render();
};
