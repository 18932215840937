import { ReactElement, useState } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { Round } from "../../../persistence/model/Round";
import { Player } from "../../../persistence/model/Player";
import { PlayerHelper } from "../../../persistence/helpers/PlayerHelper";
import { MatchHelper } from "../../../persistence/helpers/MatchHelper";
import { RoundHelper } from "../../../persistence/helpers/RoundHelper";
import { Match } from "../../../persistence/model/Match";
import { MatchEntry } from "./match-entry/MatchEntry";
import { ByeEntry } from "./bye-entry/ByeEntry";
import { IoChevronBack } from "react-icons/io5";

export const RoundEditView = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const [playersState, setPlayersState] = useState<Player[]>(appState.tournament.players);
  const [roundsState, setRoundsState] = useState<Round[]>(appState.tournament.rounds);

  const roundId = appState.stateData?.roundId!!;
  const roundIndex = RoundHelper.getIndexById(roundsState, roundId);
  const roundNumber = roundIndex + 1;
  const editingRound = roundsState[roundIndex];

  const returnState = appState.stateData?.fromState ?? States.ROUND_PROPOSE;

  function render(): ReactElement {
    return (
      <div className="page bg-edit">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1 className="in-progress">
                <span className="preheader light">Round {roundNumber}</span>
                <br />
                <span>Edit results</span>
              </h1>
            </div>
            <div className="subnavigation double">
              <div className="subnav-button alert link" onClick={handleDiscardTournament}>
                <IoChevronBack /> Back
              </div>
              <div className="subnavigation single-right">
                <div className="subnav-button next">Tap Winning Players</div>
              </div>
            </div>
          </div>
          <div className="entries">
            {renderMatches()}
            {renderBye()}
          </div>
        </div>
        {renderDoneButton()}
      </div>
    );
  }

  function renderMatches() {
    return editingRound.matches.map((match, i) => {
      let table = i + 1;
      return (
        <MatchEntry
          key={match.id}
          className="pairings-entry-instance"
          table={table}
          match={match}
          onPlayerClicked={handleMatchWinnerChange}
          onDrawClicked={handleMatchDrawChange}
        />
      );
    });
  }

  function renderBye() {
    if (editingRound.bye) {
      let table = editingRound.matches.length + 1;
      return <ByeEntry key={editingRound.bye} className="pairings-entry-instance" table={table} bye={editingRound.bye} />;
    }
  }

  function renderDoneButton() {
    return (
      <div className="bottom-navigation single">
        <h1 className="link next" onClick={handleSaveTournament}>
          Done
        </h1>
      </div>
    );
  }

  function handleMatchWinnerChange(match: Match, player: Player) {
    let opponent = MatchHelper.getOpponent(match, player);
    let updatedMatch = MatchHelper.createFinishedMatch(match, player.id, opponent);
    let updatedRound = MatchHelper.updateMatch(editingRound, updatedMatch);
    let updatedRounds = RoundHelper.updateRound(roundsState, updatedRound);
    setRoundsState(updatedRounds);

    let updatedPlayers = PlayerHelper.updatePlayerScores(updatedRounds, playersState);
    setPlayersState(updatedPlayers);
  }

  function handleMatchDrawChange(match: Match) {
    let updatedMatch = MatchHelper.createFinishedMatch(match);
    let updatedRound = MatchHelper.updateMatch(editingRound, updatedMatch);
    let updatedRounds = RoundHelper.updateRound(roundsState, updatedRound);
    setRoundsState(updatedRounds);

    let updatedPlayers = PlayerHelper.updatePlayerScores(updatedRounds, playersState);
    setPlayersState(updatedPlayers);
  }

  function handleSaveTournament(): void {
    setAppState({
      state: returnState,
      tournament: {
        players: playersState,
        rounds: roundsState,
      },
    });
  }

  function handleDiscardTournament(): void {
    setAppState({
      state: returnState,
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  return render();
};
