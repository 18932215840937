import { RecoilRoot, useRecoilValue } from "recoil";
import { TournamentCreateView } from "../tournament/tournament-create/TournamentCreateView";
import { StandingsInterimView } from "../tournament/standings-interim/StandingsInterimView";
import { RoundPlayingView } from "../tournament/round-playing/RoundPlayingView";
import { StandingsFinalView } from "../tournament/standings-final/StandingsFinalView";
import { RoundEditView } from "../tournament/round-edit/RoundEditView";
import { CurrentState } from "../tournament/CurrentState";
import { AppState, Persistance, recoilState } from "../../persistence/Persistance";
import { States } from "../../persistence/States";
import { RoundProposeView } from "../tournament/round-propose/RoundProposeView";
import { PlayersEditView } from "../tournament/players-edit/PlayersEditView";
import { ClearApp } from "./ClearApp";
import { Analytics } from "./Analytics";
import { PlayersAddView } from "../tournament/players-add/PlayersAddView";

function App() {
  return (
    <RecoilRoot>
      <Persistance />
      <Analytics />
      <AppView />

      <CurrentState />
      <ClearApp />
    </RecoilRoot>
  );
}

const AppView = () => {
  const appState = useRecoilValue<AppState>(recoilState);

  return renderSwitch(appState.state);

  function renderSwitch(state: string) {
    switch (state) {
      default:
      case States.TOURNAMENT_CREATE:
        return <TournamentCreateView />;
      case States.PLAYERS_ADD:
        return <PlayersAddView />;
      case States.PLAYERS_EDIT:
        return <PlayersEditView />;
      case States.ROUND_PROPOSE:
        return <RoundProposeView />;
      case States.ROUND_PLAYING:
        return <RoundPlayingView />;
      case States.ROUND_EDIT:
        return <RoundEditView />;
      case States.STANDINGS_FINAL:
        return <StandingsFinalView />;
      case States.STANDINGS_INTERIM:
        return <StandingsInterimView />;
    }
  }
};

export default App;
