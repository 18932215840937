import { ReactElement } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { StandingsEntry } from "./standings-entry/StandingsEntry";
import { ResistanceHelper } from "../../../persistence/helpers/ResistanceHelper";
import { RoundEditButton } from "../../dialogs/round-edit/RoundEditButton";
import { EndTournamentButton } from "../../dialogs/end-tournament/EndTournamentButton";

export const StandingsFinalView = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const resistances = ResistanceHelper.getSortedStandings(appState.tournament);

  function render(): ReactElement {
    return (
      <div className="page bg-final-standings">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1 className="standings">
                Final
                <br />
                <span>Standings</span>
              </h1>
            </div>
            <div className="subnavigation double">
              <RoundEditButton currentState={States.STANDINGS_FINAL} />
              <div className="subnav-button next link" onClick={handleNextRound}>
                Add Extra Round
              </div>
            </div>
          </div>
          <div className="entries final-standings">{renderResistances()}</div>
        </div>
        <div className="bottom-navigation single">
          <EndTournamentButton className="standings link" showDialog={false} />
        </div>
      </div>
    );
  }

  function renderResistances() {
    return resistances.map((resistance, i) => {
      return <StandingsEntry key={resistance.player.id} className="standings-entry-instance" index={i} resistance={resistance} />;
    });
  }

  function handleNextRound(): void {
    // before stopping etc, the matches in the round should have been edited and updated

    setAppState({
      state: States.ROUND_PROPOSE,
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  return render();
};
