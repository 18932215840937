import { ReactElement } from "react";
import { isStagingEnvironment } from "../../persistence/helpers/TestHelper";

export const ClearApp = () => {
  function render(): ReactElement {
    return isStagingEnvironment ? (
      <div className="staging bg-staging link" onClick={handleClearApp}>
        <div className="subnav-button edit link">Clear App</div>
      </div>
    ) : (
      <span />
    );
  }

  function handleClearApp() {
    window.localStorage.clear();
    window.location.reload();
  }

  return render();
};
