import { ReactElement } from "react";
import { useRecoilValue } from "recoil";
import { AppState, recoilState } from "../../persistence/Persistance";
import { isProductionEnvironment } from "../../persistence/helpers/TestHelper";

export const CurrentState = () => {
  const appState = useRecoilValue<AppState>(recoilState);

  function render(): ReactElement {
    return isProductionEnvironment ? (
      <span />
    ) : (
      <div className="staging bg-staging">
        <div className="subnav-button dark">
          Current state: &nbsp;
          <span className="players"> {appState.state}</span>
        </div>
      </div>
    );
  }

  return render();
};
