import { ReactElement } from "react";
import { Player } from "../../../../persistence/model/Player";
import { Score } from "../../../../persistence/model/Score";
import { useRecoilValue } from "recoil";
import { AppState, recoilState } from "../../../../persistence/Persistance";
import { PlayerHelper } from "../../../../persistence/helpers/PlayerHelper";

interface Props {
  className: any;
  table: number;
  bye: string;
}

export const ByeEntry = (props: Props): ReactElement => {
  const appState = useRecoilValue<AppState>(recoilState);

  const player = PlayerHelper.getPlayerById(appState.tournament.players, props.bye);

  return (
    <div className={`entry entry-dark  ${props.className}`}>
      <div className="entry-number">{props.table}</div>
      {renderPlayer(player)}
      <div className="entry-dash">-</div>
      <div className="entry-player">Bye</div>
    </div>
  );

  function renderPlayer(player: Player) {
    return (
      <div className="entry-player">
        <span className="entry-player-name">{player.name}</span> <span className="entry-standing">{renderScore(player.score)}</span>
      </div>
    );
  }

  function renderScore(score: Score) {
    return (
      <div className="div">
        {score.win}/{score.lose}/{score.draw}
      </div>
    );
  }
};
