import React from "react";
import { RoundEditDialog } from "./RoundEditDialog";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";

interface Props {
  currentState: string;
}

export const RoundEditButton = (props: Props) => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const [open, setOpen] = React.useState(false);

  const showEditRounds = appState.tournament.rounds.length > 0;

  function render() {
    return (
      <div>
        {showEditRounds && renderButton()}
        {/* Dialog cannot be inside of the button div, otherwise the dialog won't close. */}
        <RoundEditDialog open={open} rounds={appState.tournament.rounds} onClose={handleCloseDialog} />
      </div>
    );
  }

  function renderButton() {
    return (
      <div className="subnav-button alert link" onClick={handleClickOpen}>
        Edit Results
      </div>
    );
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleCloseDialog(value: string | null) {
    setOpen(false);

    if (value !== null) {
      goToRoundEditView(value);
    }
  }

  function goToRoundEditView(roundId: string) {
    setAppState({
      state: States.ROUND_EDIT,
      stateData: {
        roundId: roundId,
        fromState: props.currentState,
      },
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  return render();
};
