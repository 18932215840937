import { Player } from "../model/Player";
import { ScoreHelper } from "./ScoreHelper";
import { uuid } from "../../utils/UuidUtils";
import { Round } from "../model/Round";
import { MapUtils } from "../../utils/MapUtils";

export class PlayerHelper {
  public static create(name: string, id: string = uuid()): Player {
    return {
      id: id,
      name: name,
      late: false,
      dropped: false,
      history: [],
      byed: false,
      score: ScoreHelper.create(),
    };
  }

  public static createPlayers(names: Map<string, string>) {
    let filtered = MapUtils.filter(names, (_, name) => name !== "");
    return Array.from(filtered, ([id, name]) => {
      return this.create(name, id);
    });
  }

  public static getOpponentIds(player: Player): string[] {
    return player.history.map((h) => h.opponentId);
  }

  public static getPlayerById(players: Player[], id: string): Player {
    return players.find((p) => p.id === id)!!;
  }

  public static updatePlayerScores(rounds: Round[], players: Player[]): Player[] {
    let updatedPlayers = players.map((p) => {
      let updatedScore = ScoreHelper.calculateScore(rounds, p);

      return {
        ...p,
        ...{
          score: updatedScore,
        },
      };
    });

    return updatedPlayers;
  }
}
