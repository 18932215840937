import { ReactElement, useState } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { Round } from "../../../persistence/model/Round";
import { Player } from "../../../persistence/model/Player";
import { PlayersEntry } from "./players-entry/PlayersEntry";
import { PlayersInput } from "./players-input/PlayersInput";
import { IoChevronBack } from "react-icons/io5";

export const PlayersEditView = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const [playersState, setPlayersState] = useState<Player[]>(appState.tournament.players);
  const [roundsState, setRoundsState] = useState<Round[]>(appState.tournament.rounds);

  const hasEnoughPlayers = playersState.length >= 3;

  function render(): ReactElement {
    return (
      <div className="page bg-edit">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1 className="edit">
                Add/Drop
                <br />
                Players
              </h1>
            </div>
            <div className="subnavigation double">
              <div className="subnav-button alert link" onClick={handleDiscardTournament}>
                <IoChevronBack /> Back
              </div>
            </div>
          </div>
          <div className="entries">
            {playersState.map((p, i) => {
              return !p.dropped && <PlayersEntry key={p.id} className="entry" index={i} player={p} onPlayerRemove={handlePlayerDrop} />;
            })}
            <PlayersInput className="entry" index={playersState.length} onPlayerAdd={handlePlayerAdd} />
          </div>
        </div>
        <div className="bottom-navigation single">{renderStartButton()}</div>
      </div>
    );
  }

  function renderStartButton() {
    let className = `link greyed-out`;

    if (hasEnoughPlayers) className += ` next`;

    return (
      <h1 className={className} onClick={handleSaveTournament}>
        Done
      </h1>
    );
  }

  function handlePlayerAdd(player: Player) {
    let updatedPlayers = [...playersState, ...[player]];
    setPlayersState(updatedPlayers);
  }

  function handlePlayerDrop(player: Player) {
    let droppedPlayer = {
      ...player,
      ...{
        dropped: true,
      },
    };

    let otherPlayers = playersState.filter((p) => p.id !== droppedPlayer.id);
    let updatedPlayers = [...otherPlayers, ...[droppedPlayer]];

    setPlayersState(updatedPlayers);
  }

  function handleSaveTournament(): void {
    if (!hasEnoughPlayers) return;

    setAppState({
      state: States.ROUND_PROPOSE,
      tournament: {
        players: playersState,
        rounds: roundsState,
      },
    });

    resetStates();
  }

  function handleDiscardTournament(): void {
    setAppState({
      state: States.ROUND_PROPOSE,
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });

    resetStates();
  }

  function resetStates(): void {
    setPlayersState(appState.tournament.players);
    setRoundsState(appState.tournament.rounds);
  }

  return render();
};
